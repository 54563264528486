
// SHOP PAGE
#shop-page {
    margin-top: 25px;
    margin-bottom: 25px;

    h1 {
        text-align: center;
    }

    .searchbar {
        height: 55px;
        margin: 0rem auto;
    }

    .filter-dropdown {
        background-color: white;
        color: $penusa-blue;
        padding-left: 5px;
        padding-right: 5px;
        border-bottom: 4px solid white;

        &:hover {
            font-weight: bold;
            border-color: $penusa-orange;
        }

        &.select {
            color: $penusa-blue;
            font-weight: bold;
        }

    }

    #filter-label {
        height: 40px;
        margin-right: 15px;
        line-height: 35px;
        border-bottom: 4px solid white;
    }

    @media (max-width: $sm) {
        #filter-label ~ span {
            display: block;
            width: 100%;
        }
    }

    // aligns grid to rest of page
    .nextui-grid-container {
        padding-left: 0px;
        padding-right: 0px;

        @media (max-width: $md) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $md) {
            padding-left: 15px;
            padding-right: 15px;
        }

        button:disabled {
            background-color: transparent;
            color: #ccc;
        }
    }

    .card {
        border-radius: initial;
        padding: 15px;
        outline: 1px solid #ccc;
        border: none;
        border-bottom: 4px solid transparent;

        &:hover {
            border-bottom-color: $penusa-orange;
        }

        .card-image * {
            margin: 15px;
        }

        .card-price {
            display: block;
            font-size: 2rem;
            font-family: 'Crimson Text', serif;
            color: $penusa-blue;
            font-weight: bold;
            line-height: normal;
        }
    }

    // Cart Icon
    .cart-icon {
        position: fixed;
        border-radius: 100px;
        height: 75px;
        width: 75px;
        bottom: 2rem;
        right: 2rem;
        background-color: $penusa-blue;
        box-shadow: 0px 0px 5px #ccc;

        &:disabled {
            background-color: #ccc;
        }
    }

}

// Modal Styling General
.nextui-backdrop-content {
    max-width: initial;
    cursor: auto;
    background-color: rgba(0,0,0,.15);
}

// .nextui-modal-body {
//     min-height: 100%;
//     overflow-y: auto;
// }

// Modal Styling Product
.product-modal-main {
    padding: 1px 15px 5px;
}

.product-modal-content {
    #access-message {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        p {
            padding-left: 4px;
            margin-bottom: 0px;
        }
    
        .icon-button {
            border-radius: 50%;
            background-color: #ccc;
            padding: 3px;
            margin-left: 5px;
            vertical-align: middle;
        }
    }
    
    #access-field {
        > label {
            font-size: .9rem;
            padding-left: 4px;
            margin-bottom: 6px;
        }
    
        #access-tooltip-button {
            border-radius: 50%;
            padding: 3px;
            vertical-align: middle;
            margin-left: 5px;
            margin-bottom: 6px;
            background-color: #737373;
    
            &:hover {
                background-color: $penusa-blue;
            }
        }
    
        #access-input {
            width: 50%;
            display: flex;
            align-items: stretch;
    
            .discount-button {
                border-radius: 0px;
                background-color: $penusa-orange;
                border: none;
                border-bottom: 4px solid $penusa-orange;
                padding-bottom: 2px;
                
                span {
                    font-size: .75rem;
                }
    
                &:hover {
                    background-color: $penusa-orange;
                    border-bottom-color: $penusa-blue;
                }
            }
    
            .nextui-input-clear-button {
                padding: 10px;
            }

            @media (max-width: $sm) {
                width: 100%;
            }
        }
    }
    
    #access-message, #access-field {
        .icon-button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: none;
        }
    
        .icon-button span {
            font-size: .75rem;
        }
    }

    .added-message {
        font-weight: bold;
        color: white !important;
        background-color: $penusa-orange !important;
        border-bottom-color: $penusa-orange !important;
    }
}


.promo {
    margin-left: 5px;
    vertical-align: middle;
    padding-bottom: 6px;

    font-family: 'Crimson Text', serif;
    text-transform: uppercase;
    font-size: small;

    .material-icons {
        vertical-align: middle;
        font-size: large;
    }

    &.sale {
        color: red;
    }

    &.clearance {
        color: $penusa-orange;
    }

    &.new {
        color: green
    }
}

table th {
    color: #737373;
}

// Modal Styling Cart
#cart-modal {
    overflow-y: auto;
    
    @media (min-width: $md) {
        max-height: calc(-100px + 100vh);
    }
}
    
.modal-container {
    display: flex;
    align-items: stretch;
    max-height: inherit;

    .modal-listOfItems {
        width: 60%;
        overflow-y: auto;
        background-color: rgb(238, 238, 238);
    }

    .modal-contact {
        width: 40%;
    }

    .modal-contact-padding {
        padding: 20px 25px;
    }


    @media (max-width: $md) {
        display: block;

        .modal-listOfItems {
            width: 100%;
        }

        .modal-contact {
            width: 100%;
        }

    }
}

// Styling for Cart Get Quote
.modal-contact {
    &:last-of-type {
        padding-bottom: 30px;
    }

    #modal-title {
        padding: 1rem 1rem .3rem;
    }

    .close-button {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .custom-input {
        width: 100%;
    }
}

.modal-listOfItems {
    h3 {
        text-align: center;
        width: 100%;
    }

    .cart-card {
        width: 100%;
        padding: 5px 15px;
        margin: 15px auto;
        border: 1px solid #ccc;
        filter: none;

        .row {
            padding-top: 20px !important;
            padding-bottom: 0px !important;
        }
    }
}

// Modal Cart Footer
.nextui-modal-footer {
    justify-content: center;
    flex-direction: column;
}