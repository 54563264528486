// FAQ
#faq-page-header .background-img {
    background: url("/background/main-2.jpg"), rgba(31,31,79,0.7);
    border-bottom-color: $penusa-blue;
    
    h1 {
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.faq {
    .faq-question {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    @media (max-width: $md) {
        padding-left: 30px;
        padding-right: 30px;
    }
}