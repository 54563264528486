// Index Page
.jumbotron {
    background: url("/background/main-2.jpg"), rgba(31,31,79,0.7);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: 0% 50%;
    min-height: 30vh;
    display: flex;
    align-items: center;
    // justify-content: center;

    .jumbotron-content {
        padding: 10% 20px;
        text-align: center;
        color: white;
        width: 100%;
    }

    .title {
        font-weight: 600;
    }

    .subtitle {
        font-size: 1.3rem;
    }

    @media (min-width: $md) {
        min-height: 40vh;

        .jumbotron-content {
            padding: 30px 5%;
        }

        .searchbar {
            max-width: 700px;
        }
    }
}

// Carousel
.carousel-indicators {
    // position: static;
    bottom: -50px;

    [data-bs-target] {
        background-color: $penusa-orange;
    }
}
.carousel-control-prev-icon, .carousel-control-next-icon {
    display: none;
}

// Promo
#promo {
    margin-top: 25px;

    .promo-item {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        &.orange .promo-content {
            background-color: $penusa-orange !important;
        }

        &.blue .promo-content {
            background-color: $penusa-blue !important;

            h2 {
                color: white;
            }
        }

        @media (max-width: $md) {
            flex-direction: column;
        }
        
        .promo-content {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 200px;
            width: 100%;
            padding: 25px 15px;
            text-align: center;

            h2 {
                margin-bottom: 1.1rem;
            }

            a {
                background-color: #fff;
                padding: 1rem;
            }
        }

        .promo-image {
            width: 100%;
            min-height: 200px;
            background-size: cover;
            background-position: center;
        }
    }
}

// Categories
#categories {
    margin-top: 25px;

    h2 {
        text-align: center;
    }

    .category {
        height: 200px;
        overflow-y: hidden;
        margin: 15px 0px;
        position: relative;
        display: flex;

        .category-name {
            opacity: 0;
            font-size: 1.1rem;
            width: 100%;
            text-align: center;
            position: absolute;
            background-color: rgba($color: #fff, $alpha: .9);
            padding: 20px;
            margin-bottom: 0px;
            bottom: 0px;
            transition: all .3s ease;
        }

        &:hover .category-name {
            opacity: 1;
        }

        img {
            width: 100%;

            // TO POSITION PROMO IMAGES
            // position: relative;
            // transform: translateY(-150px);
        }
    }
}
