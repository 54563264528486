// CONTACT PAGE
#contact-page-header .background-img {
    background-image: url("/background/main-1.jpg");
}


#contact-page {    
    h1 {
        margin-bottom: 1rem;
    }

    .row {
        gap: 15px;
    }

    @media (max-width: $md) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

#contact-form {
    margin-top: 25px;

    .submit {
        text-align: right;
    }

}