@import "variables";
@import "index";
@import "contact";
@import "shop";
@import "faq";


// Fonts
* {
    letter-spacing: .3pt !important;
    box-sizing: border-box;
}

h1, h2, h3, h4 {
    font-family: 'Crimson Text', serif;
    margin-bottom: .7rem;
}


a {
    text-decoration: none;
    color: $penusa-blue;
    border-bottom: 4px solid #fff;
    transition: all .3s ease;

    &:hover:not(.carousel-control-prev, .carousel-control-next) {
        font-weight: bold;
        color: $penusa-blue;
        border-bottom: 4px solid $penusa-orange;
    }
}

p.small-text {
    font-size: .8rem;
}

button, input[type="submit"], .secondary-button {
    color: $penusa-blue;
    font-size: 1rem;
    padding: 15px 20px;
    border: none;
    border-bottom: 4px solid white;
    background-color: white;
    transition: all .3s ease;

    &.orange:hover {
        font-weight: bold;
        border-bottom-color: $penusa-orange;
    }
    &.blue:hover {
        font-weight: bold;
        border-bottom-color: $penusa-blue;
    }

    span {
        vertical-align: bottom;
    }

    &:disabled {
        background: var(--nextui-colors-accents1);
        border-bottom-color: transparent;
        color: var(--nextui-colors-accents7);
        transform: none;
        box-shadow: none;
        pointer-events: none;
        cursor: initial;
    }
}

.secondary-button {
    background-color: $penusa-blue;
    border-bottom-color: $penusa-blue;
    color: white;
    // outline: 2px solid $penusa-orange;
    width: 100%;
    text-align: center;

    &:hover {
        font-weight: bold;
        color: white;
        border-bottom-color: $penusa-orange;
    }
}

// Layout/nav
nav {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .links a {
        display: inline-block;
        text-align: center;
        padding: 10px;
        margin: 0px 5px;
        width: 100px;
    }

    .logo {
        max-height: 30px;
    }

    @media (min-width: $md) {
        padding: 25px 30px;

        .links a {
            padding: 15px;
            margin: 0px 15px;
        }

        .logo {
            max-height: 50px;
        }            
    }
}

// Input
.custom-input {
    position: relative;
    margin-bottom: 15px;

    input[type="text"], textarea {
        width: 100%;
        padding: 23px 10px 10px;
        background-color: rgba($penusa-blue,.15);
        border: 1px solid white;
    }

    label {
        position: absolute;
        left: 15px;
        top: 15px;
        transition: all .2s ease;
    }

    input[type="text"]:focus, input[type="text"]:valid, textarea:focus, textarea:valid {
        background-color: white;
        border-color: $penusa-orange;

        ~ label {
            left: 10px;
            top: 5px;
            font-size: .7rem;
            color: $penusa-orange;
        }
    }

    textarea {
        min-height: 100px;

        &:focus, &:valid  {
            padding-top: 10px;
        }
        
        &:focus ~ label, &:valid ~ label {
            display: none;
        }
    }
}

// Covid Banner
p.covid {
    padding: 5px 15px;
    text-align: center;
    background-color: $penusa-orange;
    margin-bottom: 0px;
    line-height: 1.2;
}

// Sections
.row {
    padding-top: 25px;
    padding-bottom: 25px;

    .row {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    @media (max-width: $sm) {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.background-img {
    height: 40vh;
    background-position: center;
    background-size: cover;
    border-bottom: 10px solid $penusa-orange;
    margin: 0px -15px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

// FOOTER
.cta {

    h3 strong {
        color: $penusa-blue;
        border-bottom: 2px solid $penusa-orange;
    }

    .link-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    @media (max-width: $md) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

footer {
    padding-left: 30px;
    padding-right: 30px;

    h4 {
        font-size: 1.2rem;
    }

    .small-text {
        text-align: center;
    }
}

.contact-info {
    display: block;
    font-size: 1rem;
    margin-bottom: .1rem;

    span.material-icons {
        font-size: 1rem;
        vertical-align: top;
        margin-right: 5px;
        margin-top: 5px;
    }

    a {
        display: inline-block;
        max-width: calc(100% - 25px);
    }
    
}


.searchbar {
    height: 55px;
    margin: 1rem auto;

    label, span{
        border-radius: 0px;
    }
    
    .nextui-input-content--right {
        width: 25px;
    }
}

.nextui-input-wrapper {
    border-radius: 0px !important;
}